<template>
  <div id="header-poster-container" class="header-poster-container">
    <slot>
      <div class="default-content">
        {{title}}
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: "index",
    props: {
      backgroundImg: {
        type: String,
        default: () => require('../../assets/img/about/banner.png')
      },
      title: {
        type: String,
        default: ''
      }
    },
    mounted() {
      const el = document.getElementById('header-poster-container')
      if (el) {
        el.setAttribute('style', `background: url("${this.backgroundImg}") no-repeat;`)
      }
    }
  }
</script>

<style lang="less" scoped>
  @media screen and (min-width: 768px) {
    .header-poster-container {
      padding: 20px 50px;
      div {
        font-size: 24px;
        font-family: Akrobat;
        font-weight: 800;
        line-height: 32px;
      }
    }
  }
  @media screen and (min-width: 820px) {
    .header-poster-container {
      padding: 20px 50px;
      div {
        font-size: 28px;
        font-family: Akrobat;
        font-weight: 800;
        line-height: 32px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .header-poster-container {
      padding: 40px 110px;
      div {
        font-size: 32px;
        font-family: Akrobat;
        font-weight: 800;
        line-height: 40px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .header-poster-container {
      padding: 40px 110px;
      div {
        font-size: 38px;
        font-family: Akrobat;
        font-weight: 800;
        line-height: 42px;
      }
    }
  }
.header-poster-container {
  /*background: url("../../assets/img/about/banner.png") no-repeat;*/
  background-size: contain!important;
  // 背景图比例 640 : 101
  height: calc(100vw * 101 / 640);
}
</style>